/* styles/Feed.module.css */
.FeedContainer {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  overflow-y: scroll;


  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }


}
.FeedPostContainer {
  display: flex;
  align-items: center; /* Align items to the start of the container */
  flex-direction: column;
  padding: 16px;
  border: 1px solid #e0e0e0;
  margin-bottom: 16px;
  border-radius: 8px;
  max-width: 800px;
  position: relative;
}
.PostDateTime {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  color: #555; /* Add color styling as needed */
}
.FeedContainer .PostContents{
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

.FeedPostContainer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
}

.FeedPostContainer p {
  margin-bottom: 16px;
  padding: 5px;
}

.ReactionContainer {
  display: flex;
  flex-direction: column; /* Display items in a column layout */
  align-items: flex-start; /* Align items to the start of the container */
}

.ReactionContainer span {
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 8px; /* Add margin-bottom to separate reaction icons */
}

/* Add more styling as needed */
