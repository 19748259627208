.ImageGallery {
    margin: 5px;
    border: 1px solid #ccc;
    float: left;
    width: 180px;
    height: 180px;
    /* overflow-y: scroll; */
  }

  .ImageGallery:hover {
    border: 1px solid #777;
  }

  .ImageGallery img {
    width: 100%;
    height: auto;
  }

  .ImageGallery.description {
    padding: 15px;
    text-align: center;
  }
  .ImageGalleryPopupContainer {
    display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  }

  .ExitButton {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 50px;
    width: 50px;
    font-size: x-large;
    color: white;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .ImageGalleryPopupImage {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }
