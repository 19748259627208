/* Styles for the range selector container */
.RangeSelectorContainer {
    margin-bottom: 20px;
    text-align: center;
  }

  /* Styles for the range input and its associated text */
  .RangeSelectorContainer input[type="range"] {
    width: 50%;
    margin-top: 8px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    background: #d3d3d3;
    outline: none;
    border-radius: 5px;

  }

  .RangeSelectorContainer input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
  }

  .RangeSelectorContainer input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
  }

  /* Style for the paragraph displaying the current value */
  .RangeSelectorContainer p {
    margin-top: 5px;
    text-align: center;
    font-weight: bold;
  }
