/* General layout */
.chatPageContainer {
  display: flex;
  gap: 1rem;
  width: 100vw;
  height:90vh;
}

.chatBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  gap: 1rem;
  margin: 20px 5px;
  padding: 0 2px;
}

.chatWindow {
  width: 80%;
  margin: 20px;
  padding: 0 5px;
  float: right;
  position: relative;
}
.chatWindow h2{
  text-align: center;
}



ul {
  list-style-type: none;
  width: 100%;
  padding: 0;

}

/* Chat profiles */
.chatProfile {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  /* padding: 10px; */
  margin: 10px 0;
  width: 80%;
  min-width: 30px;
  height: 60px;

  display: flex;
  align-items: center;
}

.chatProfile img {

  height: 100%;
  border-radius: 0%;
  margin-right: 10px;
  padding: 0;
  border-radius: 5px;
}

.chatProfile h2 {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

/* Chat messages */
.ChatHistoryContainer {
  overflow-y: scroll;
  /* height: 80%; */
  scroll-padding: 5px;
  margin-bottom: 10px;
}

.ChatMessageAI{
    border: 2px solid #dedede;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom:10px;
    margin-left: 0px;
    margin-right: 10px;
    position: relative;
    min-height: 50px;
}
.ChatMessageUSER{
  position: relative;
  border: 2px solid #dedede;
  background-color: #d6d1d1;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom:10px;
  margin-left: 10px;
  margin-right: 0px;
  min-height: 50px;

}


.ChatMessageUSER img{
  height: 60px;
  border-radius: 50%;
  float: right;
  right: 0;
  top: 0;
}
.ChatMessageAI img {
  height: 60px;
        border-radius: 50%;
        float: left;
        left: 0;
        top: 0;
}
.ChatMessageAI div {
  padding-bottom: 5px;
}
.ChatMessageUSER div {
  padding-bottom: 5px;
}
.ChatMessageUSER .time {
  color: #999;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ChatMessageUSER p {
  margin: 5px;
  right: 80px;
}

.ChatMessageAI .time {
  color: #aaa;
  position: absolute;
  bottom: 0;
  right: 0;
}

.ChatMessageAI p {
  margin: 5px;
}

.ChatMessageAI span {
  margin-right: 10px;
  float:right;
  cursor:pointer;
  padding: 2px;



}



/* Chat input and history */
.ChatInputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 8%; /* Adjust height as needed */
  background-color: lightgray;
  border-radius:4px;

}

.ChatInputContainer input {
  flex: 1;
  font-size: x-large;
  height: auto;
  /* Add other styling */
  outline: none;
  border: none;
  background-color: lightgray;
  border-radius:4px;



}

.ChatInputContainer button {
  /* flex: 0 0 auto; */
  height: 80%;
  margin: 2px;
  border-radius:4px;
  background-color: blue;
  color: white;
  /* Add button styling */
  width: 100px;
  right:5px;
  position: relative
}

.ChatSearchContainer{
  display: flex;
  margin: 10px 0;

  width: 80%;
  min-width: 30px;
  height: 8%; /* Adjust height as needed */
  background-color: lightgray;
  border-radius:5px;

}
.ChatSearchContainer input{
  font-size: x-large;
  height: auto;
  /* Add other styling */
  outline: none;
  border: none;
  background-color: lightgray;
  border-radius:4px;
  width: 100%;
}
.ChatSearchContainer span{
  /* flex: 0 0 auto; */
  height: 80%;
  margin: 2px;
  border-radius:4px;
  right:5px;



}

/* Animation styles */
.ChatAnimationContainer {

}



.ChatAnimationCaption {
  margin: auto;
  font-family: arial;
  font-size: 20px;
  color: rgb(212, 207, 207);
  position: relative;
}
