.ProfilePage{
  width: 100vw;
  align-items: center;
}
.cardGrid{
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(270px,1fr));
  gap: 20px;
  margin: 20px 0;

}
.card{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    border-radius: 0.35rem;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.5);
    background-color: rgba(245,245,245,0.973);
    transform-style: preserve-3d;
    transition: 150ms;
    cursor: pointer;
    transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
    height: 400px;
    width: 250px;
    margin: 10px;
    padding: 10px;
  }
  .card:hover{
    --translate-y: -2px;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.5);
    opacity: 1;
  }
  .card .front,
  .card .back{
    position: absolute;
    padding: 1rem;
    backface-visibility: hidden;
  }
  .card .back{
    transform: rotateY(180deg);
  }
  .card.flip{
    --rotate-y:180deg

  }


  .card .front{
    left:0;
  }

  .ProfileCardImage {
    border-radius: 5px 5px 0 0;
    width: 100%;
  }

  .ProfileCardBio {
    padding: 2px 16px;
  }
  .ProfileCardName{
    margin: auto;
    text-align: center;
  }
  .ProfileCardActionContainer{
    position: absolute;
    bottom: 10px;
    display: inline-block;


  }
  .ProfileCardActionContainer span{
    margin: 10px;

  }
  .ProfileCardActionContainer a:link{
    background-color: transparent;
    text-decoration: none;
    color: black;
  }
  .ProfileCardActionContainer a:visited{
    background-color: transparent;
    text-decoration: none;
    color: black;

  }
  .ProfileSearchContainer{
    display: flex;
    margin: 10px 0;
    margin: auto;
    width: 80%;
    min-width: 30px;
    height: 8%; /* Adjust height as needed */
    background-color: lightgray;
    border-radius:5px;
    position: relative;
    top: 10px;
    /* left:50% */

  }
  .ProfileSearchContainer input{
    font-size: x-large;
    height: auto;
    /* Add other styling */
    outline: none;
    border: none;
    background-color: lightgray;
    border-radius:4px;
    width: 100%;
    text-align: center;
  }
  .ProfileSearchContainer span{
    /* flex: 0 0 auto; */
    height: 80%;
    margin: 2px;
    border-radius:4px;
    right:5px;



  }
